<template>
  <div class="flex flex-col bg-white">
    <PageHeader
      title="Eaton Engineering Case Study"
      sub_title=""
      img="/images/caseStudies_header.webp"
    />
    <div class="container mx-auto flex flex-wrap py-16">
      <div class="w-full text-5xl text-center mb-4">Precision Positioning</div>
      <div class="w-full flex flex-wrap p-2 my-6 text-lg">
        <div class="w-1/2 flex justify-center p-2">
          <ImageContainer img="/images/contact_header.jpg" alt="" />
        </div>
        <div class="w-1/2 flex flex-col justify-center p-2">
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
        </div>
      </div>
      <div class="flex flex-col p-2">
        <div class="text-3xl pb-2">Section header</div>
        <div class=" text-lg text-gray-900">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet
          quisquam quis qui! Voluptatem, placeat dicta. Perspiciatis omnis quas, dignissimos hic
          voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet quisquam quis
          qui! Voluptatem, placeat dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptatem, placeat dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Perspiciatis omnis quas, dignissimos hic voluptas odio itaque aut fuga molestias mollitia
          unde saepe illo, eveniet quisquam quis qui! Litaque aut fuga molestias mollitia unde saepe
          illo rorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio, eveniet quisquam quis qui! Voluptatem, placeat dicta.
        </div>
      </div>
      <div class="flex flex-col p-2">
        <div class="text-3xl pb-2">Section header</div>
        <div class=" text-lg text-gray-900">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet
          quisquam quis qui! Voluptatem, placeat dicta. Perspiciatis omnis quas, dignissimos hic
          voluptas odio itaque aut fuga molestias mollitia unde saepe illo, eveniet quisquam quis
          qui! Voluptatem, placeat dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Voluptatem, placeat dicta.Lorem ipsum dolor sit amet consectetur adipisicing elit.
          Perspiciatis omnis quas, dignissimos hic voluptas odio itaque aut fuga molestias mollitia
          unde saepe illo, eveniet quisquam quis qui! Litaque aut fuga molestias mollitia unde saepe
          illo rorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis omnis quas,
          dignissimos hic voluptas odio, eveniet quisquam quis qui! Voluptatem, placeat dicta.
        </div>
      </div>
      <div class="w-full flex flex-wrap p-2 my-6 text-lg">
        <div class="w-1/2 flex justify-center p-2">
          <ImageContainer img="/images/contact_header.jpg" alt="" />
        </div>
        <div class="w-1/2 flex flex-col justify-center p-2">
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
          <div class="my-1">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ducimus maxime possimus
            aperiam, perspiciatis animi amet incidunt error adipisci non quaerat? Ex nihil, ullam
            animi velit eius magnam veniam placeat cum!
          </div>
        </div>
        
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from "@/components/PageHeader";
import ImageContainer from "@/components/ImageContainer";

export default {
  name: "About",
  components: { PageHeader, ImageContainer },
};
</script>
